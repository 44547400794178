import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const TermsAndConditionContent = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Acceptable use policy')}
          lang={pageContext.lang}
          meta={[
            {
              property: `robots`,
              content: 'noindex,nofollow',
            },
          ]}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Acceptable use policy')}
            descriptionText={t(
              'The policy explaining the allowed usage of the Services'
            )}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <TermsAndConditionContent>
                <p>
                  Customer agrees not to, and not to allow third parties to use
                  the Services:
                </p>
                <br />
                <p>
                  {' '}
                  - to violate, or encourage the violation of, the legal rights
                  of others (for example, this may include allowing end users to
                  infringe or misappropriate the intellectual property rights of
                  others in violation of the German copyright laws);
                </p>
                <p> - to engage in, promote or encourage illegal activity;</p>
                <p>
                  {' '}
                  - for any unlawful, invasive, infringing, defamatory or
                  fraudulent purpose (for example, this may include phishing,
                  creating a pyramid scheme or mirroring a website);{' '}
                </p>
                <p>
                  {' '}
                  - to intentionally distribute viruses, worms, Trojan horses,
                  corrupted files, hoaxes, or other items of a destructive or
                  deceptive nature;{' '}
                </p>
                <p>
                  {' '}
                  - to interfere with the use of the Services, or the equipment
                  used to provide the Services, by customers, authorized
                  resellers, or other authorized users;{' '}
                </p>
                <p>
                  {' '}
                  - to disable, interfere with or circumvent any aspect of the
                  Services;{' '}
                </p>
                <p>
                  {' '}
                  - to generate, distribute, publish or facilitate unsolicited
                  mass email, promotions, advertisings or other solicitations
                  ("spam"); or{' '}
                </p>
                <p>
                  {' '}
                  - to use the Services, or any interfaces provided with the
                  Services, to access any other esaqa product or service in a
                  manner that violates the terms of service of such other esaqa
                  product or service.{' '}
                </p>
                <br />
              </TermsAndConditionContent>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
